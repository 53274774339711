import * as userActions from './constants.js'
import {handleRespErr} from '../../../helpers/respErrorsHandler'
import {toast} from "react-toastify";
import {GetAxios} from '../../../helpers/axios';
import {API_URLS, buildAPIUrl, URLS_LOCAL_GROUPS} from "../../../urls";
import axios from 'axios';
import {deleteLoginCookies, setLoginCookies} from "../../../helpers/cookies";

export function userSignInAction(email, password, router, cb) {
    return dispatch => {
        dispatch(userSignInActionRequest());

        const userData = {
            email: email,
            password: password
        };

        axios.defaults.withCredentials = true;

        axios.post(buildAPIUrl(API_URLS.signIn), userData, {
            headers: {
                'Content-Type': 'application/json',
                'Front-Host': 'client'
            }
        })
            .then((res) => {
                let cookies = {
                    loggedIn: {
                        value: true,
                        options: {}
                    },
                    accessToken: {
                        value: res.data.access_token,
                        options: {

                        }
                    },
                    user_id: {
                        value: res.data.public_id,
                        options: {

                        }
                    },
                    user_name: {
                        value: res.data.user_name?.first_name + ' ' + res.data.user_name?.last_name,
                        options: {

                        }
                    },
                    user_public_id: {
                        value: res.data.public_id,
                        options: {

                        }
                    },
                    is_email_confirmed: {
                        value: res.data.is_email_confirmed,
                        options: {

                        }
                    }
                }
                setLoginCookies(cookies);
                dispatch(userSignInActionSuccess(res.data))

            })
            .catch(handleRespErr(dispatch, toast.error, userSignInActionFailure, cb))
    }
}

function userSignInActionRequest() {
    return {
        type: userActions.USER_SIGN_IN_REQUEST,
    }
}

function userSignInActionSuccess(userData) {
    return {
        type: userActions.USER_SIGN_IN_SUCCESS,
        payload: userData
    }
}

function userSignInActionFailure(error) {
    return {
        type: userActions.USER_SIGN_IN_FAILURE,
        error: error
    }
}

export function userSignOutAction() {

    return dispatch => {
        let axios = GetAxios(dispatch);

        axios.put(buildAPIUrl(API_URLS.signOut), {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                deleteLoginCookies();
                dispatch(userSignOutSuccess());
            })
            .catch(handleRespErr(dispatch, toast.error, userSignOutFailure))
    }
}

function userSignOutFailure() {
    return {
        type: userActions.USER_SIGN_OUT_FAILURE
    }
}

export function userSignOutSuccess() {
    return {
        type: userActions.USER_SIGN_OUT_SUCCESS
    }
}

export function userSignUpAction(data, router, cb) {

    return dispatch => {
        dispatch(userSignUpActionRequest());

        axios.post(buildAPIUrl(API_URLS.signUp), data, {
            headers: {
                'Content-Type': 'application/json',
                "Front-Host": "client"
            }
        })
            .then((res) => {

                dispatch(userSignUpActionSuccess(res.data));

                let cookies = {
                    loggedIn: {
                        value: true,
                        options: {}
                    },
                    accessToken: {
                        value: res.data.access_token,
                        options: {

                        }
                    },
                    user_id: {
                        value: res.data.public_id,
                        options: {

                        }
                    },
                    user_name: {
                        value: res.data.user_name?.first_name + ' ' + res.data.user_name?.last_name,
                        options: {

                        }
                    },
                    user_public_id: {
                        value: res.data.public_id,
                        options: {

                        }
                    },
                    is_email_confirmed: {
                        value: res.data.is_email_confirmed,
                        options: {

                        }
                    }
                }
                setLoginCookies(cookies);
                router.push('/buy-token')
                cb();
            })
            .catch(handleRespErr(dispatch, toast.error, userSignUpActionFailure))
    }
}

function userSignUpActionRequest() {
    return {
        type: userActions.USER_SIGN_UP_REQUEST,
    }
}

function userSignUpActionSuccess(userData) {
    return {
        type: userActions.USER_SIGN_UP_SUCCESS,
        payload: userData
    }
}

function userSignUpActionFailure(error) {
    return {
        type: userActions.USER_SIGN_UP_FAILURE,
        error: error
    }
}

export function confirmEmailAction(data, cb) {

    return dispatch => {
        let axios = GetAxios(dispatch);
        dispatch(confirmEmailActionRequest());

        axios.post(buildAPIUrl(API_URLS.confirmEmail), data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(confirmEmailActionSuccess())
                let cookies = {
                    is_email_confirmed: {
                        value: true,
                        options: {}
                    }
                }
                setLoginCookies(cookies);
                dispatch(userSignInActionSuccess({is_email_confirmed: true}))
            })
            .catch(err => {
                dispatch(confirmEmailActionFailure(err.response?.data?.error))
                // cb('Something went wrong')
                console.log(err.response?.data?.error)
            })
    }
}

function confirmEmailActionRequest() {
    return {
        type: userActions.USER_CONFIRM_EMAIL_ACTION_REQUEST,
    }
}

function confirmEmailActionSuccess() {
    return {
        type: userActions.USER_CONFIRM_EMAIL_ACTION_SUCCESS,
    }
}

function confirmEmailActionFailure(error) {
    return {
        type: userActions.USER_CONFIRM_EMAIL_ACTION_FAILURE,
        payload: error
    }
}

export function sendConfirmationLink(id) {

    return dispatch => {
        let axios = GetAxios(dispatch);
        dispatch(sendConfirmationLinkRequest());

        axios.post(buildAPIUrl(API_URLS.sendConfirmationLink), id, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(sendConfirmationLinkSuccess());
                toast.success('Confirmation link has been sent.')
            })
            .catch(err => {
                dispatch(sendConfirmationLinkFailure(err))
            })
    }
}

function sendConfirmationLinkRequest() {
    return {
        type: userActions.USER_SEND_CONFIRM_EMAIL_LINK_REQUEST,
    }
}

function sendConfirmationLinkSuccess() {
    return {
        type: userActions.USER_SEND_CONFIRM_EMAIL_LINK_SUCCESS,
    }
}

function sendConfirmationLinkFailure(error) {
    return {
        type: userActions.USER_SEND_CONFIRM_EMAIL_LINK_FAILURE,
        error: error
    }
}

export function userSendResetEmailAction(email) {
    return dispatch => {

        dispatch(userSendResetEmailActionRequest())
        const userData = {
            email: email
        };
        axios.post(buildAPIUrl(API_URLS.sendResetEmailLink), userData, {
            headers: {
                'Content-Type': 'application/json',
                'Front-Host': 'client'
            }
        })
            .then(res => {
                dispatch(userSendResetEmailActionSuccess());
                toast.success('Reset link has been sent.')
            })
            .catch(handleRespErr(dispatch, toast.error, userSendResetEmailActionFailure))
    }
}

function userSendResetEmailActionRequest() {
    return {
        type: userActions.USER_SEND_RESET_EMAIL_REQUEST,
    }
}

function userSendResetEmailActionSuccess(message) {
    return {
        type: userActions.USER_SEND_RESET_EMAIL_SUCCESS,
    }
}

function userSendResetEmailActionFailure() {
    return {
        type: userActions.USER_SEND_RESET_EMAIL_FAILURE,
    }
}

export function onSetNewPassword(password, router) {
    return dispatch => {

        dispatch(onSetNewPasswordRequest());

        axios.post(buildAPIUrl(API_URLS.setPassword), password, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                dispatch(onSetNewPasswordSuccess());
                toast.success(res.data.message || 'Password has been changed');
                router.push(URLS_LOCAL_GROUPS.signIn)
            })
            .catch(handleRespErr(dispatch, toast.error, onSetNewPasswordFailure))
    }
}

function onSetNewPasswordRequest() {
    return {
        type: userActions.USER_SET_NEW_PASSWORD_REQUEST
    }
}

function onSetNewPasswordSuccess() {
    return {
        type: userActions.USER_SET_NEW_PASSWORD_SUCCESS
    }
}

function onSetNewPasswordFailure() {
    return {
        type: userActions.USER_SET_NEW_PASSWORD_FAILURE
    }
}

export function getOptions() {
    return dispatch => {
        let axios = GetAxios(dispatch);
        dispatch(getOptionsRequest());

        axios.get(buildAPIUrl(`${API_URLS.options}/clients`), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                dispatch(getOptionsSuccess(res.data))
            })
            .catch(handleRespErr(dispatch, toast.error, getOptionsFailure))
    }
}

function getOptionsRequest() {
    return {
        type: userActions.GET_OPTIONS_REQUEST,
    }
}

function getOptionsSuccess(options) {
    return {
        type: userActions.GET_OPTIONS_SUCCESS,
        payload: options
    }
}

function getOptionsFailure(error) {
    return {
        type: userActions.GET_OPTIONS_FAILURE,
        payload: error
    }
}
