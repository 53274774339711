import * as stakingActions from '../../actions/stakingAction/constants'

const initialState = {
    products: {
        product: null,
        products: null,
        isLoading: true,
        isLoadingProduct: true
    },
    deposits: {
        deposits: null,
        deposit: null,
        isLoading: true,
        isLoadingDeposit: true,
        isProceedDeposit: false,
        fromErc20Data: null
    },
    transactions: {
        transactions: null,
        isLoading: true
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case stakingActions.ADD_TRX_HASH_REQUEST:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    isProceedDeposit: true,
                }
            };
        case stakingActions.ADD_TRX_HASH_SUCCESS:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    isProceedDeposit: false,
                }
            };
        case stakingActions.ADD_TRX_HASH_FAILURE:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    isProceedDeposit: false,
                }
            };
        case stakingActions.GET_STAKING_PRODUCTS_REQUEST:
            return {
                ...state,
                products: {
                    ...state.products,
                    isLoading: true,
                }
            };
        case stakingActions.GET_STAKING_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: {
                    ...state.products,
                    products: action.payload,
                    isLoading: false,
                }
            };
        case stakingActions.GET_STAKING_PRODUCTS_FAILURE:
            return {
                ...state,
                products: {
                    ...state.products,
                    products: null,
                    isLoading: false,
                }
            };
        case stakingActions.GET_STAKING_PRODUCT_REQUEST:
            return {
                ...state,
                products: {
                    ...state.products,
                    isLoadingProduct: true,
                }
            };
        case stakingActions.GET_STAKING_PRODUCT_SUCCESS:
            return {
                ...state,
                products: {
                    ...state.products,
                    product: action.payload,
                    isLoadingProduct: false,
                }
            };
        case stakingActions.GET_STAKING_PRODUCT_FAILURE:
            return {
                ...state,
                products: {
                    ...state.products,
                    product: null,
                    isLoadingProduct: false,
                }
            };
        case stakingActions.CREATE_DEPOSIT_REQUEST:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    isProceedDeposit: true,
                }
            };
        case stakingActions.CREATE_DEPOSIT_FROM_ERC20_SUCCESS:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    fromErc20Data: action.payload,
                    isProceedDeposit: false,
                }
            };
        case stakingActions.CREATE_DEPOSIT_FAILURE:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    isProceedDeposit: false,
                }
            };
        case stakingActions.GET_STAKING_TRANSACTIONS_REQUEST:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    isLoading: true,
                }
            };
        case stakingActions.GET_STAKING_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    transactions: action.payload,
                    isLoading: false,
                }
            };
        case stakingActions.GET_STAKING_TRANSACTIONS_FAILURE:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    transactions: null,
                    isLoading: false,
                }
            };
        case stakingActions.GET_STAKING_DEPOSITS_REQUEST:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    isLoading: true,
                }
            };
        case stakingActions.GET_STAKING_DEPOSITS_SUCCESS:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    deposits: action.payload,
                    isLoading: false,
                }
            };
        case stakingActions.GET_STAKING_DEPOSITS_FAILURE:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    deposits: null,
                    isLoading: false,
                }
            };
        case stakingActions.GET_STAKING_DEPOSIT_REQUEST:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    isLoadingDeposit: true,
                }
            };
        case stakingActions.GET_STAKING_DEPOSIT_SUCCESS:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    deposit: action.payload,
                    isLoadingDeposit: false,
                }
            };
        case stakingActions.GET_STAKING_DEPOSIT_FAILURE:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    deposit: null,
                    isLoadingDeposit: false,
                }
            };
        case stakingActions.CANCEL_DEPOSIT_REQUEST:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    isProceedDeposit: true,
                }
            };
        case stakingActions.CANCEL_DEPOSIT_SUCCESS:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    isProceedDeposit: false,
                }
            };
        case stakingActions.CANCEL_DEPOSIT_FAILURE:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    isProceedDeposit: false,
                }
            };
        case stakingActions.SET_DEFAULTS:
            return {
                products: {
                    products: null,
                    isLoading: true
                },
                deposits: {
                    deposits: null,
                    deposit: null,
                    isLoading: true,
                    isLoadingDeposit: true,
                },
                transactions: {
                    transactions: null,
                    isLoading: true
                },
            };
        default:
            return state
    }
}