import * as profileActions from '../../actions/profileActions/constants';
import * as optionsActions from '../../actions/optionsAction/constants';

const initialState = {
    transactionsOptions: null,
    userOptions: null,
    productOptions: null,
    stakingOptions: null,
    currencies: null,
    isLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {

        case optionsActions.GET_CURRENCIES_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case optionsActions.GET_CURRENCIES_SUCCESS:
            return {
                ...state,
                currencies: action.payload,
                isLoading: false
            };
        case optionsActions.GET_CURRENCIES_FAILURE:
            return {
                ...state,
                currencies: null,
                isLoading: false
            };
        case optionsActions.GET_TRANSACTIONS_OPTIONS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case optionsActions.GET_TRANSACTIONS_OPTIONS_SUCCESS:
            return {
                ...state,
                transactionsOptions: action.payload,
                isLoading: false
            };
        case optionsActions.GET_TRANSACTIONS_OPTIONS_FAILURE:
            return {
                ...state,
                transactionsOptions: null,
                isLoading: false
            };
        case profileActions.GET_USER_OPTIONS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case profileActions.GET_USER_OPTIONS_SUCCESS:
            return {
                ...state,
                userOptions: action.payload,
                isLoading: false
            };
        case profileActions.GET_USER_OPTIONS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case profileActions.GET_PRODUCT_OPTIONS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case profileActions.GET_PRODUCT_OPTIONS_SUCCESS:
            return {
                ...state,
                productOptions: action.payload,
                isLoading: false
            };
        case profileActions.GET_PRODUCT_OPTIONS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state
    }
}