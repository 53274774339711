import * as authActions from '../../actions/authActions/constants'
import * as profileActions from '../../actions/profileActions/constants'
import {getCookie} from "../../../helpers/cookies";

const initialState = {
    loggedIn: getCookie('loggedIn'),
    user_id: getCookie('user_id'),
    is_email_confirmed: getCookie('is_email_confirmed'),
    processed: false,
    accessToken: getCookie('accessToken'),
    isAuthorized: false,
    user_public_id: getCookie('user_public_id'),
    user_name: getCookie('user_name'),
    confirmLoading: true,
    confirmEmailMessage: '',
    isLoading: true,
    isProcessedChangeProfile: false,
    isProcessedLogin: false,
    isReferralsLoading: true,
    wallet: '',
    locale: null,
    referrals: null,
    user: {
        personal: null,
        documents: null,
        details: null,
        headerInfo: null
    }
};

export default (state = initialState, action) => {
    switch (action.type) {

        case profileActions.GET_USER_LOCALE_REQUEST:
            return {
                ...state,
                locale: null
            };
        case profileActions.GET_USER_LOCALE_SUCCESS:
            return {
                ...state,
                locale: action.payload
            };
        case profileActions.GET_USER_LOCALE_FAILURE:
            return {
                ...state,
                locale: null
            };
        case profileActions.SET_DEFAULTS:
            return {
                ...state,
                user: {
                    personal: null,
                    documents: null,
                    details: null,
                    headerInfo: null
                },
                isLoading: true,
                wallet: ''
            };
        case authActions.USER_SIGN_IN_REQUEST:
            return {
                ...state,
                isProcessedLogin: true
            };
        case authActions.USER_SIGN_IN_SUCCESS:
            return {
                ...state,
                processed: false,
                isProcessedLogin: false,
                is_email_confirmed: action.payload.is_email_confirmed,
                nationality: action.payload.country,
                phone_number: action.payload.phone_number,
                loggedIn: true,
                accessToken: action.payload.access_token,
                user_id: action.payload.public_id,
                user_name: action.payload.user_name?.first_name + ' ' + action.payload.user_name?.last_name,
                user_public_id: action.payload.public_id
            };
        case authActions.USER_SIGN_IN_FAILURE:
            return {
                ...state,
                isProcessedLogin: false
            };

        case authActions.USER_SIGN_UP_REQUEST:
            return {
                ...state,
                isProcessedLogin: true
            }
        case authActions.USER_SIGN_UP_SUCCESS:
            return {
                ...state,
                processed: false,
                isProcessedLogin: false,
                is_email_confirmed: action.payload.is_email_confirmed,
                nationality: action.payload.country,
                phone_number: action.payload.phone_number,
                loggedIn: true,
                accessToken: action.payload.access_token,
                user_id: action.payload.public_id,
                user_name: action.payload.user_name?.first_name + ' ' + action.payload.user_name?.last_name,
                user_public_id: action.payload.public_id,
            };
        case authActions.USER_SIGN_UP_FAILURE:
            return {
                ...state,
                isProcessedLogin: false
            }
        case authActions.USER_UNCOMPLETED_SIGN_IN_SUCCESS:
            return {
                ...state,
                processed: false,
                loggedIn: true,
                stage: action.payload.stage,
                user_id: action.payload.id,
                accessToken: action.payload.access_token,
                refreshToken: action.payload.refresh_token,
                public_id: action.payload.public_id
            };

        case authActions.USER_CONFIRM_EMAIL_ACTION_REQUEST:
            return {
                ...state,
                confirmLoading: true,
            };
        case authActions.USER_CONFIRM_EMAIL_ACTION_SUCCESS:
            return {
                ...state,
                confirmLoading: false,
                confirmEmailMessage: 'Your email has been confirmed!'
            };
        case authActions.USER_CONFIRM_EMAIL_ACTION_FAILURE:
            console.log('pay', action.payload)
            return {
                ...state,
                confirmLoading: false,
                confirmEmailMessage: action.payload || 'Incorrect information or the link is out of date. Log in to your account and send a new confirmation request.'
            };

        case profileActions.USER_GET_PERSONAL_INFO_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case profileActions.USER_GET_PERSONAL_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: {
                    ...state.user,
                    personal: action.payload
                },
            };
        case profileActions.USER_GET_PERSONAL_INFO_FAILURE:
            return {
                ...state,
                isLoading: false,
                user: {
                    ...state.user,
                    personal: null
                },
            };

        case profileActions.GET_USER_REFERRALS_REQUEST:
            return {
                ...state,
                isReferralsLoading: true,
            };
        case profileActions.GET_USER_REFERRALS_SUCCESS:
            return {
                ...state,
                isReferralsLoading: false,
                referrals: action.payload
            };
        case profileActions.GET_USER_REFERRALS_FAILURE:
            return {
                ...state,
                isReferralsLoading: false,
                referrals: null
            };

        case profileActions.GET_USER_WALLET_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case profileActions.GET_USER_WALLET_SUCCESS:
            return {
                ...state,
                wallet: action.payload,
                isLoading: false
            };
        case profileActions.GET_USER_WALLET_FAILURE:
            return {
                ...state,
                wallet: '',
                isLoading: false,
            };

        case profileActions.ADD_USER_WALLET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                wallet: action.payload
            };

        case authActions.USER_SEND_RESET_EMAIL_REQUEST:
            return {
                ...state,
                isProcessedLogin: true
            };
        case authActions.USER_SEND_RESET_EMAIL_SUCCESS:
            return {
                ...state,
                isProcessedLogin: false,
            };
        case authActions.USER_SEND_RESET_EMAIL_FAILURE:
            return {
                ...state,
                isProcessedLogin: false,
            };

        case authActions.USER_SET_NEW_PASSWORD_REQUEST:
            return {
                ...state,
                isProcessedLogin: true
            };
        case authActions.USER_SET_NEW_PASSWORD_SUCCESS:
            return {
                ...state,
                isProcessedLogin: false,
            };
        case authActions.USER_SET_NEW_PASSWORD_FAILURE:
            return {
                ...state,
                isProcessedLogin: false,
            };

        case profileActions.USER_CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                isProcessedChangeProfile: true
            };
        case profileActions.USER_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                isProcessedChangeProfile: false,
            };
        case profileActions.USER_CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                isProcessedChangeProfile: false,
            };
        case profileActions.USER_UPDATE_PERSONAL_INFO_REQUEST:
            return {
                ...state,
                isProcessedChangeProfile: true
            };
        case profileActions.USER_UPDATE_PERSONAL_INFO_SUCCESS:
            return {
                ...state,
                isProcessedChangeProfile: false,
            };
        case profileActions.USER_UPDATE_PERSONAL_INFO_FAILURE:
            return {
                ...state,
                isProcessedChangeProfile: false,
            };

        case authActions.USER_SIGN_OUT:
            return initialState;
        case authActions.USER_SIGN_OUT_SUCCESS:
            return {
                ...state,
                loggedIn: false,
                user_id: '',
                wallet: '',
                enable_2fa: false,
                processed: false,
                accessToken: '',
                isAuthorized: false,
                refreshToken: '',
                user_name: '',
                user_public_id: ''
            };
        default:
            return state
    }
}