import React, {Suspense } from 'react';
import {Switch, Route} from 'react-router-dom';
import Loadable from 'react-loadable';
import { ToastContainer } from "react-toastify";
import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import authRoutes from "../authRoutes";
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import "react-toastify/dist/ReactToastify.css";
import Page404 from "./layout/404";
import { Flip } from 'react-toastify';
//commit
const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

const App = () => {

        const authRouter = authRoutes.map((route, index) => {
          return (route.component) ? (
              <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                      <route.component {...props} />
                  )} />
          ) : null;
        });

        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader/>}>
                        <Switch>
                            {authRouter}
                            <Route exact={true} path="/404" component={Page404} />
                            <Route path="/" component={AdminLayout} />
                        </Switch>
                    </Suspense>
                </ScrollToTop>
                <ToastContainer
                    transition={Flip}
                    theme={"colored"}
                    autoClose={5000}
                    pauseOnHover={true}
                />
            </Aux>
        );
}

export default App;
