import * as wsActions from './constants.js'
import {buildWSUrl} from "../../../urls";

let ws;

export function setWSConnection(url) {
    return dispatch => {

        dispatch({type: wsActions.WS_ON_CONNECT});

        ws = new WebSocket(buildWSUrl(url))

        ws.onopen = (e) => {
            dispatch({type: wsActions.WS_ON_OPEN});
        }
        ws.onclose = () => {
            dispatch({type: wsActions.WS_ON_CLOSE});
        }
        ws.onmessage = e => {
            dispatch(wsOnMessage(JSON.parse(e.data)))
        }

    }
}

export function setLastOrdersWSConnection(url) {
    return dispatch => {

        dispatch({type: wsActions.WS_ON_ORDERS_CONNECT});

        ws = new WebSocket(buildWSUrl(url))

        ws.onopen = (e) => {
            dispatch({type: wsActions.WS_ON_ORDERS_OPEN});
        }
        ws.onclose = () => {
            dispatch({type: wsActions.WS_ON_ORDERS_CLOSE});
        }
        ws.onmessage = e => {
            dispatch(wsOnOrdersMessage(JSON.parse(e.data)))
        }

    }
}

function wsOnMessage(e) {
    return {
        payload: e,
        type: wsActions.WS_ON_MESSAGE,
    }
}

function wsOnOrdersMessage(e) {
    return {
        payload: e,
        type: wsActions.WS_ON_ORDERS_MESSAGE,
    }
}