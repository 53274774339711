export const GET_PAYMENT_FORM_DATA_REQUEST = 'GET_PAYMENT_FORM_DATA_REQUEST';
export const GET_PAYMENT_FORM_DATA_SUCCESS = 'GET_PAYMENT_FORM_DATA_SUCCESS';
export const GET_PAYMENT_FORM_DATA_FAILURE = 'GET_PAYMENT_FORM_DATA_FAILURE';

export const GET_PROMO_CODE_INFO_REQUEST = 'GET_PROMO_CODE_INFO_REQUEST';
export const GET_PROMO_CODE_INFO_SUCCESS = 'GET_PROMO_CODE_INFO_SUCCESS';
export const GET_PROMO_CODE_INFO_FAILURE = 'GET_PROMO_CODE_INFO_FAILURE';

export const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE';

export const CLEAR_DATA = 'CLEAR_DATA';